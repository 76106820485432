.DaySeparator {
  padding: 8px 16px;
  z-index: 1;
}

.DaySeparator-rise {
  z-index: 3;
}

.DaySeparator-top {
  position: absolute;
  width: 100%;
  z-index: 2;
}